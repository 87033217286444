import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { ViewProjectadmin, ViewProjectrequirementadmin } from "./SalaryTable";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AddProjectAdminModal, AddProjectrequirementAdminModal } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
const Requirements = () => {
    const selectedProjectId = sessionStorage.getItem('selectedProjectId');
    const selectedProjectname = sessionStorage.getItem('selectedProjectname');

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [showaddrequirementModal, setshowaddrequirementModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { password, username } = billpunchbasicauth;
  const getProjects = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        projectid: selectedProjectId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetProjectGatherings`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const openaddprojectModal = () => {
    setshowaddrequirementModal(true);
  };

  const closeaddprojectModal = () => {
    setshowaddrequirementModal(false);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    getProjects();
  }, []);
  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);
  return (
    <>
      <AddProjectrequirementAdminModal
        show={showaddrequirementModal}
        closeModal={closeaddprojectModal}
        getProjects={getProjects}
      />
      <div>
        <div className=" row mt-md-4 mt-sm-4 p-0 mt-sm-3">
          <h4 className="col mx-md-4 mx-sm-3 mb-sm-4 d-flex align-items-center ">
            {" "}
            Requirements of {selectedProjectname}
          </h4>

          <div className=" col-5 mr-md-4 mr-sm-3 d-flex justify-content-end">
            <div className="px-2 d-flex justify-content-end">
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>{" "}
            <div className="px-2">
            <Button variant="success"
              className="btn  btn-sm  justify-content-center align-items-center"
              style={{
                borderRadius: ".4em",
                height: "2.5em",
                // width: "8rem",
                border: "None",
              }}
              onClick={openaddprojectModal}
            >
              Add Requirements
            </Button>
            </div>
          
            <Link to="/retailer/Createproject">
            <Button variant="info"
              className="btn  btn-sm justify-content-center align-items-center"
              style={{
                borderRadius: ".4em",
                height: "2.5em",
                // width: "8rem",
                border: "None",
              }}
            >
          Back
            </Button>
            </Link>
          
          </div>
        </div>

        <div
          className=" p-0 mx-md-3"
          style={{
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <ViewProjectrequirementadmin
            data={data}
            searchTerm={searchTerm}
            getProjects={getProjects}
          />
        </div>
      </div>
    </>
  );
};

export default Requirements;
